<template>
    <section class="content">
    <div class="container-fluid">
      <div class="row justify-content-end">
        <div class="col-12">
            <div v-html="textContent"></div>
        </div>
      </div>
    </div>
    </section>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';

export default {
    name: 'Informasi',
    data() {
        return {
            errors: [],
            categories: [
                {
                    id: 'debug',
                    label: 'debug'
                },
                {
                    id: 'info',
                    label: 'info'
                },
                {
                    id: 'fee',
                    label: 'fee'
                },
                {
                    id: 'tracking',
                    label: 'tracking'
                },
                {
                    id: 'sla',
                    label: 'sla'
                },
                {
                    id: 'delete',
                    label: 'delete'
                },
            ],
            textContent: '',
            loading: false,
            method: '',
            date:'',
            code:'',
            category: ''
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        this.code = this.$route.params.id;
        this.loadContent();
    },
    components: {
    },
    methods: {
        loadContent: function()
        {
            authFetch("/info/"+this.code)
            .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.text();
            })
            .then((text) => {
                this.textContent = text;
            });
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
    }
}
</script>